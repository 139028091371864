.filters .range-slider .ngrs-range-slider {
  margin-top: 0;
  padding: 6px 10px;
  border-color: white; }
  .filters .range-slider .ngrs-range-slider .ngrs-handle {
    border: none;
    background-color: #34699a; }
  .filters .range-slider .ngrs-range-slider .ngrs-join {
    background-color: white;
    background-image: none; }

.filters .multiselect-parent {
  width: 100%; }

.filters .dropdown-multiselect .btn {
  border-radius: 3px !important;
  border-color: white;
  text-align: left; }
  .filters .dropdown-multiselect .btn .caret {
    float: right;
    margin-top: 10px; }

.filters .dropdown-multiselect .dropdown-menu {
  border-color: white;
  overflow-x: hidden !important; }
  .filters .dropdown-multiselect .dropdown-menu .checkbox {
    margin: 0; }

.btn-filter-remove {
  border-radius: 10px;
  margin-bottom: 0.33em;
  margin-right: 0.2em;
  padding: 1px 0.4em; }
  .btn-filter-remove i {
    margin-left: 0.15em; }
  .btn-filter-remove.btn-info {
    background-color: #ED8C01;
    border-color: #ED8C01;
    color: #FFF; }
    .btn-filter-remove.btn-info:hover {
      background-color: #fea423;
      border-color: #fea423;
      color: #FFF; }
