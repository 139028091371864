.invoices-filter .row {
  margin-bottom: 10px; }

.invoices-filter select {
  display: inline-block;
  width: 60px;
  padding: 6px; }

.invoices-filter label.filter-type {
  font-weight: normal; }
