.quickView {
  position: absolute;
  background-color: #FFF;
  border: 1px solid #808080;
  border-radius: 3px;
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.6);
  z-index: 100; }

.quickViewText {
  line-height: 1.66;
  margin: 20px;
  white-space: pre-line; }

.col-price {
  width: 120px; }

.item-qty {
  min-width: 30px !important; }
