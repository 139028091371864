#orderpad {
  margin-bottom: 175px; }
  #orderpad table#single_entry td, #orderpad table#single_entry th {
    border: none; }
  #orderpad table#single_entry thead {
    color: #FFF;
    background-color: #34699a; }
    #orderpad table#single_entry thead tr {
      padding: 5px !important; }
  #orderpad .input-group .input-group-addon {
    color: #FFF;
    background-color: #34699a; }

.btn-block.active {
  background-color: #1b354e !important; }

.largeButton {
  padding: 20px;
  text-align: left !important; }
  .largeButton h2 {
    color: #FFF;
    display: inline-block; }
    .largeButton h2 .small {
      color: #FFF;
      font-size: .5em; }
  .largeButton i {
    margin-right: 20px; }

.smallButtons a {
  margin-bottom: 15px; }
  .smallButtons a i {
    margin-top: .2em;
    line-height: inherit; }
  .smallButtons a span {
    margin-left: .5em;
    font-size: 1.1em; }

.summary-table thead tr {
  border-bottom: 2px #e3e3e3 solid; }
  .summary-table thead tr th {
    border: none; }

.summary-table tbody tr {
  border-top: 1px #ededed solid; }
  .summary-table tbody tr td {
    border-top: none; }

.copyPaste textarea {
  margin-bottom: 12px; }

.copyPaste ol {
  padding-left: 0; }

.fileUpload {
  position: relative;
  overflow: hidden;
  margin: 10px; }
  .fileUpload input.upload {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0); }

.partautocomplete {
  border: 1px solid #9ABFD9;
  z-index: 9999999;
  padding: 5px;
  position: absolute;
  background: white;
  width: 57%; }
