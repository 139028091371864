.jumbotron {
  background: #0061A0;
  -webkit-box-shadow: inset 0 5px 5px #005287;
  -moz-box-shadow: inset 0 5px 5px #005287;
  box-shadow: inset 0 5px 5px #005287;
  color: #FFF; }
  .jumbotron h1 {
    -webkit-text-shadow: -5px 8px 4px #003c63;
    -moz-text-shadow: -5px 8px 4px #003c63;
    text-shadow: -5px 8px 4px #003c63;
    margin-bottom: 25px; }
  .jumbotron .small {
    font-size: 1.1em; }

.jumbotronPadded {
  padding: 60px 0 70px 0; }

.normalizeThumbnailHeight .thumbnail {
  height: 500px; }
  .normalizeThumbnailHeight .thumbnail p {
    font-size: 1.1em;
    line-height: 1.4em; }
  .normalizeThumbnailHeight .thumbnail .btn {
    width: 86%;
    position: absolute;
    bottom: 35px;
    left: 7%; }

.jtOverride {
  -webkit-box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.3);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 130%; }
  .jtOverride h1 {
    -webkit-text-shadow: -5px 5px 4px rgba(0, 0, 0, 0.3);
    -moz-text-shadow: -5px 5px 4px rgba(0, 0, 0, 0.3);
    text-shadow: -5px 5px 4px rgba(0, 0, 0, 0.3); }
  .jtOverride p {
    -webkit-text-shadow: -2px 2px 2px rgba(0, 0, 0, 0.6);
    -moz-text-shadow: -2px 2px 2px rgba(0, 0, 0, 0.6);
    text-shadow: -2px 2px 2px rgba(0, 0, 0, 0.6); }

blockquote {
  border-left-color: white;
  margin: 30px 0; }

.normalizeECommHeight {
  height: 240px; }

.callToAction {
  box-sizing: border-box;
  padding: 12px;
  background: #0061A0;
  color: #FFF; }
  .callToAction p.lead {
    font-size: 2.5em; }
  .callToAction p {
    font-size: 1.3em; }
