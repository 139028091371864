.modal-header {
  border-bottom: none; }

.modal-open ul {
  list-style-type: none !important;
  padding-left: 0; }
  .modal-open ul li {
    font-size: .9em; }
    .modal-open ul li label {
      font-weight: normal; }

.modal-footer {
  border-top: none; }
  .modal-footer .btn {
    border-radius: 0; }
