.page-header {
  border: none; }

.asterisk {
  color: #d9534f; }

.radioLeft {
  margin-left: 15px; }

.picMobile {
  width: 80px; }

.picSmall {
  width: 150px; }

.picMedium {
  width: 150px; }

.picLarge {
  width: 150px; }
