h1 {
  font-size: 2.15em;
  margin-top: 0; }

.xofy.text-muted {
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 7px; }

@media screen and (max-width: 1024px) {
  .xofy.text-muted {
    font-size: 1em;
    margin-top: 5px; } }

label.baseline {
  bottom: -6px;
  position: relative; }

label.sortby {
  color: #707070;
  margin-right: 0.5em; }

.form-control.sorting {
  width: 8em; }

.form-control.instasearch {
  width: 17em; }

.cart-added {
  border: 4px solid #5cb85c; }
