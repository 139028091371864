.options-applied {
  margin: 0 -15px;
  padding: 15px;
  background-color: rgba(0, 97, 160, 0.08);
  border: 1px solid rgba(0, 66, 109, 0.08);
  border-bottom: 0;
  font-size: 1.125em; }
  .options-applied .panel {
    border: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin-bottom: 0; }
  .options-applied .facet-panel {
    margin-bottom: 10px; }
    .options-applied .facet-panel .panel-heading {
      background-color: rgba(0, 97, 160, 0.08);
      border-bottom: solid 1px #ccc;
      color: #0061A0;
      padding: 0; }
      .options-applied .facet-panel .panel-heading h4 i {
        color: rgba(0, 97, 160, 0.5); }
    .options-applied .facet-panel .panel-body {
      background-color: rgba(0, 97, 160, 0.08);
      font-size: 0.9em;
      padding: 0; }

.facet-options-wrapper {
  margin: 0 -15px 30px;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1); }
  .facet-options-wrapper .facet-panel {
    border: none;
    margin-bottom: 10px; }
    .facet-options-wrapper .facet-panel .panel-heading {
      background: rgba(0, 0, 0, 0.05);
      border-bottom: solid 1px #ccc;
      padding: 0;
      color: #0070ba; }
      .facet-options-wrapper .facet-panel .panel-heading h4 {
        padding-top: 0.5em; }
    .facet-options-wrapper .facet-panel .panel-body {
      background-color: #e1e1e1;
      font-size: 0.9em;
      padding: 0.25em 0.25em 0.33em 0.25em; }
      .facet-options-wrapper .facet-panel .panel-body ul li {
        margin-top: 0.1em; }
        .facet-options-wrapper .facet-panel .panel-body ul li label {
          font-weight: normal;
          margin: 0; }
        .facet-options-wrapper .facet-panel .panel-body ul li .badge {
          width: 45px;
          padding: 3px 0;
          background-color: #8db9da;
          color: #FFF;
          font-size: 0.9em;
          border-radius: 10px; }
      .facet-options-wrapper .facet-panel .panel-body .btn-secondary.facet-apply-btn {
        background-color: #0061A0;
        color: #FFF; }
        .facet-options-wrapper .facet-panel .panel-body .btn-secondary.facet-apply-btn:hover {
          background-color: #0080d3; }
      .facet-options-wrapper .facet-panel .panel-body .clickable i {
        color: #0061A0; }

h4 {
  padding: 0 0 0.33em 0; }
  h4.clickable i {
    color: rgba(0, 97, 160, 0.5); }

.facet-panel .panel-body ul {
  margin-bottom: 0;
  padding: 0;
  list-style-type: none; }
  .facet-panel .panel-body ul ul li {
    margin-left: 1em;
    margin-top: 0; }

.facet-panel .panel-body .expand {
  margin-top: 0.5em;
  font-size: .9em;
  color: #0061A0;
  transition: .2s; }
  .facet-panel .panel-body .expand i {
    margin-right: 0.1em; }

.facet-panel .panel-body .expand:hover, .facet-panel .panel-body .expand:hover i {
  color: #ED8C01 !important; }

.facet-panel .panel-body .btn.facet-apply-btn {
  font-size: 0.9em;
  margin-top: 0.5em;
  padding: 0.1em 0.5em; }

.clickable {
  cursor: pointer; }
