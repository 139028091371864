.equal {
  display: flex;
  flex-wrap: wrap; }

.equal > [class*='col-'] {
  display: flex;
  flex-direction: column; }

.row.equal:after, .row.equal:before {
  display: none; }

.home-panels {
  font-family: Montserrat, sans-serif; }
  .home-panels .panel {
    border: 0px solid #fff;
    background-color: #f5f5f5;
    border-radius: 10px;
    border-bottom: 2px solid #ddd;
    height: 100%;
    margin-bottom: 70px; }
    .home-panels .panel .panel-btn {
      width: 70%;
      height: 50px;
      position: absolute;
      bottom: 45px;
      border-radius: 5px;
      left: 0;
      right: 0;
      margin: auto;
      white-space: normal;
      background-color: #707070;
      color: #FFF;
      line-height: 1.125;
      font-size: 1.125em; }
    .home-panels .panel .panel-btn:hover {
      background-color: #636363; }
    .home-panels .panel .panel-btn > span {
      display: inline-block;
      vertical-align: middle; }
    .home-panels .panel .input-group-addon {
      background-color: #707070;
      min-width: 36px;
      border: none;
      color: #ddd; }
    .home-panels .panel .form-control {
      border: none;
      background-color: #ddd;
      color: #4d4d4d; }
    .home-panels .panel label {
      font-weight: normal; }
    .home-panels .panel .fa-li {
      font-size: small; }
    .home-panels .panel .home-contact-form input {
      width: 100%;
      border: none;
      background-color: #ddd;
      color: #4d4d4d;
      padding: 3px 12px; }
    .home-panels .panel .home-contact-form input, .home-panels .panel .home-contact-form select, .home-panels .panel .home-contact-form textarea {
      margin-bottom: 1em; }
    .home-panels .panel .home-contact-form ::placeholder {
      color: #ED8C01;
      opacity: 1; }
    .home-panels .panel .home-contact-form :-ms-input-placeholder {
      color: #ED8C01; }
    .home-panels .panel .home-contact-form ::-ms-input-placeholder {
      color: #ED8C01; }
  .home-panels .panel-blue .panel-btn {
    background-color: #0061A0; }
  .home-panels .panel-blue .panel-btn:hover {
    background-color: #005287; }
  .home-panels .panel-blue .fa-li {
    color: #0061A0; }
  .home-panels .panel-green .panel-btn {
    background-color: #799A01; }
  .home-panels .panel-green .panel-btn:hover {
    background-color: #658101; }
  .home-panels .panel-green .fa-li {
    color: #799A01; }
  .home-panels .panel-orange .panel-btn {
    background-color: #ED8C01; }
  .home-panels .panel-orange .panel-btn:hover {
    background-color: #d47d01; }
  .home-panels .panel-orange .fa-li {
    color: #ED8C01; }
  .home-panels .panel-heading {
    background-color: #f5f5f5;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 30px 10px 10px; }
  .home-panels .panel-title {
    text-transform: uppercase;
    text-align: center;
    color: #949ca2;
    font-weight: bold;
    font-size: 1.5em;
    line-height: 1; }
  .home-panels .panel-body {
    margin-bottom: 25px; }

.home-panels.overlap {
  margin-top: -175px; }

.carousel {
  background: url(/assets/images/backgrounds/vallen-triangles.jpg) repeat-x;
  margin-bottom: 50px;
  max-height: 550px; }
  .carousel .carousel-img {
    filter: brightness(50%);
    margin: auto;
    max-width: 100%; }

.carousel-indicators li {
  display: none; }

.carousel-caption {
  text-align: center;
  top: 0;
  bottom: 0;
  left: 10%;
  right: 10%; }
  .carousel-caption div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%); }
  .carousel-caption h1 {
    font-size: 3.25em;
    color: #ffffff;
    letter-spacing: 0.45rem;
    font-weight: 700;
    text-transform: uppercase; }
  .carousel-caption h3 {
    color: #ffffff; }

.carousel-caption.overlap {
  bottom: 125px; }

.carousel-control.right, .carousel-control.left {
  background-image: none; }

.home-panels .panel select.ng-pristine {
  color: #ED8C01 !important; }

.partner-logos {
  text-align: center; }
  .partner-logos div {
    display: inline-block;
    margin: 1em 1.5em;
    max-width: 200px; }
    .partner-logos div img {
      max-width: 100%;
      max-height: 100%; }
  .partner-logos .logo-snap-on {
    max-width: 320px; }

h1.primary-underline {
  font-family: Montserrat, sans-serif;
  color: #0061A0;
  border-bottom: 1px solid #ED8C01; }

.csx-line {
  border-bottom: #0061A0 solid 3px;
  margin: 2em 0; }

#homePageBanner {
  margin-bottom: 1em;
  text-align: center; }
  #homePageBanner h5 {
    background-color: #0061A0;
    padding: .35em;
    font-size: 1.125em;
    margin: 0;
    color: #FFF; }
  #homePageBanner .banner-links {
    line-height: 1em;
    background-color: #ED8C01;
    padding: .25em 0; }
    #homePageBanner .banner-links ul {
      margin: 5px 0 0 0; }
      #homePageBanner .banner-links ul li {
        padding: 0 .75em; }
        #homePageBanner .banner-links ul li img {
          width: 24px;
          margin: auto; }
  #homePageBanner .bordered {
    border: 2px solid #FFF;
    border-top: none;
    border-bottom: none; }
  #homePageBanner a {
    color: #FFF; }
