.shopping_list_table {
  background-color: #FFF;
  margin-bottom: 21px !important; }
  .shopping_list_table .list-icon {
    padding-right: 0;
    max-width: 22px; }
  .shopping_list_table .list-name {
    padding-left: 4px;
    text-align: left;
    max-width: 220px; }

#search .image img {
  margin: 0 auto;
  max-height: 50px; }
