.breadcrumb {
  margin-bottom: 10px; }

.title {
  margin: 0 0 5px 0 !important;
  font-size: 2em;
  width: 95%; }

.subtitle {
  font-size: 1.3em;
  margin: 0 0 5px 0; }

.product-image-wrapper {
  padding: 20px; }
  .product-image-wrapper img {
    max-width: 100%;
    max-height: 370px; }

.product-title h1 {
  padding-top: 20px; }

@media screen and (max-width: 768px) {
  .product-title h1 {
    font-size: 1.3em; }
  .product-title h2 {
    font-size: 1.1em; } }

.product-info-wrapper {
  position: relative;
  padding-left: 30px; }

.read-more-screen {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 70px 0 0 5px;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 85%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, white 85%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 85%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
  /* IE6-9 */
  cursor: pointer; }

.read-more-container {
  max-height: 250px;
  overflow: hidden; }

.read-less {
  margin-top: 20px; }

.quantity {
  width: 70px !important;
  display: inline-block; }

.padded-submit {
  margin-top: 20px; }

.description .description-title {
  margin-top: 0; }

.attributesWrapper {
  margin-top: 20px; }
  .attributesWrapper ul.nav-tabs li.uib-tab a {
    color: #FFF !important;
    background-color: #3ab1ff; }
  .attributesWrapper ul.nav-tabs li.uib-tab a:hover {
    color: #ED8C01 !important;
    background-color: #0080d3 !important; }
  .attributesWrapper ul.nav-tabs li.active a {
    color: #ED8C01 !important;
    background-color: #0061A0 !important; }
  .attributesWrapper .attributeHeader {
    margin-top: 0; }
  .attributesWrapper .attributeGrid {
    width: 100%;
    font-size: .9em;
    margin-bottom: 20px; }
    .attributesWrapper .attributeGrid .ui-grid-header {
      border-bottom: none; }
      .attributesWrapper .attributeGrid .ui-grid-header input[type="text"].ui-grid-filter-input {
        color: #4d4d4d; }
      .attributesWrapper .attributeGrid .ui-grid-header .ui-grid-header-viewport {
        background: #0061A0; }
      .attributesWrapper .attributeGrid .ui-grid-header .ui-grid-header-cell:last-child .ui-grid-column-resizer.right {
        border: none !important; }
      .attributesWrapper .attributeGrid .ui-grid-header .header {
        background: #0061A0;
        border-color: #008fed;
        color: #FFF;
        font-weight: normal; }
      .attributesWrapper .attributeGrid .ui-grid-header .ui-grid-column-menu-button-last-col i {
        display: none; }
    .attributesWrapper .attributeGrid .ui-grid-row:nth-child(even) .ui-grid-cell {
      background-color: #ededed !important; }
    .attributesWrapper .attributeGrid .qty-hide-line {
      border: 0; }
    .attributesWrapper .attributeGrid .btn {
      padding: 4px 8px; }

.toolbox {
  position: fixed;
  top: 255px;
  right: 0; }
  .toolbox .btn {
    background: #00426d;
    color: #FFF;
    border: none;
    padding: 10px 12px; }
  .toolbox .btn:hover {
    background: #00233a; }

.select {
  margin: 5px 0; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.ui-grid-cell.visible {
  overflow: visible; }
  .ui-grid-cell.visible .ui-grid-cell-contents {
    overflow: visible; }

.ui-grid-viewport {
  overflow: inherit !important; }
