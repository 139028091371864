.search-wrapper {
  position: relative; }
  .search-wrapper .search-icon {
    position: absolute;
    top: 10px;
    right: 10px; }

.search-match {
  clear: both;
  margin: .35em 0;
  overflow: auto; }

#companySelection {
  position: relative; }
  #companySelection #companyDropdown {
    position: absolute;
    border: 1px solid #3381BD;
    color: #3381BD;
    background: #FFF;
    padding: 5px;
    -webkit-box-shadow: 7px 5px 15px 3px rgba(51, 129, 189, 0.8);
    -moz-box-shadow: 7px 5px 15px 3px rgba(51, 129, 189, 0.8);
    box-shadow: 7px 5px 15px 3px rgba(51, 129, 189, 0.8); }

form.submitted .ng-invalid {
  background-color: #f9e2e2;
  border-color: #e27c79; }

.modal-body .uib-daypicker > table {
  margin-right: auto;
  margin-left: auto; }

label.disabled {
  opacity: .65; }

.cart-review .panel-default > .panel-heading {
  background-color: #0061A0;
  color: #FFF; }

.cart-review h4.panel-title {
  margin: 0;
  padding: 0;
  width: 100%; }
  .cart-review h4.panel-title a {
    display: block; }

#orderApprovalTable .input-filter {
  max-width: 100px; }
