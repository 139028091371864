.promoBanner {
  padding: 4px 10px;
  border: solid 1px #dbe7f3;
  border-left: solid 1px #dbe7f3;
  border-right: solid 1px #dbe7f3;
  font-size: .9em; }

.listWrapper {
  clear: both;
  margin-bottom: 15px;
  padding: 12px 0;
  border: solid 1px white; }
  .listWrapper .image img {
    padding: 5px;
    margin: 0 auto;
    max-height: 80px; }
  .listWrapper .primaryInfo .title {
    margin: 0;
    font-size: 1.5em; }
    .listWrapper .primaryInfo .title a {
      color: #0061A0;
      text-decoration: underline; }
  .listWrapper .primaryInfo .description {
    color: #4d4d4d;
    font-size: 1.067em;
    margin-bottom: 5px; }
  .listWrapper .detailGrid {
    padding: 0 15px;
    margin-top: 8px; }
    .listWrapper .detailGrid table {
      margin-bottom: 3px;
      font-size: .933em; }
      .listWrapper .detailGrid table tbody td {
        vertical-align: middle; }
        .listWrapper .detailGrid table tbody td .input-group-addon {
          background-color: #0061A0;
          border: 0;
          color: #FFF;
          font-size: 1em;
          padding: 0px 4px; }
          .listWrapper .detailGrid table tbody td .input-group-addon i {
            cursor: pointer;
            padding: 6px 4px 4px; }
            .listWrapper .detailGrid table tbody td .input-group-addon i[disabled="disabled"] {
              color: #bbb;
              cursor: not-allowed; }
        .listWrapper .detailGrid table tbody td input[type="text"].form-control {
          border: 1px solid #ccc;
          font-size: 0.933em;
          height: 27px;
          padding: 0;
          text-align: center;
          width: 30px; }
        .listWrapper .detailGrid table tbody td ul.dropdown-menu {
          border-color: #ccc;
          font-size: 0.933em; }
  .listWrapper .mobile-actions {
    margin: 10px 10px 0 10px;
    padding: 7px 4px;
    background: white; }
    .listWrapper .mobile-actions i {
      margin: 0 10px;
      color: #34699a;
      cursor: pointer; }

.actions {
  background-color: #ededed;
  border-bottom: 2px solid #999;
  margin: 0;
  padding: 5px 0; }
  .actions .col-xs-12 {
    padding: 0 5px; }
  .actions .btn i {
    margin-right: 0.25em; }
  .actions .btn.btn-success.pull-right[disabled="disabled"] {
    background-color: #ccc;
    border-color: #bbb;
    color: #666;
    cursor: not-allowed; }

@media screen and (max-width: 768px) {
  .result-item-block {
    height: 242px; }
  .listWrapper .primaryInfo .title {
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.3; } }

.gridWrapper {
  cursor: pointer;
  position: relative;
  height: 365px;
  transition: .2s; }
  .gridWrapper img {
    padding: 3px;
    margin-top: 10px;
    height: 100px; }
  .gridWrapper h3 {
    margin-top: 0;
    margin-bottom: 4px;
    font-size: 1.1em; }
  .gridWrapper .description {
    font-size: .9em;
    margin-bottom: 3px; }
  .gridWrapper .price {
    margin-top: 10px;
    color: #34699a; }
  .gridWrapper .actionButtons {
    position: absolute;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 10px; }
    .gridWrapper .actionButtons .additional-actions {
      margin-top: 5px; }
      .gridWrapper .actionButtons .additional-actions i {
        margin: 0 3px;
        color: white;
        cursor: pointer; }
      .gridWrapper .actionButtons .additional-actions i:hover {
        color: #34699a; }

.gridWrapper:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }

.itemListLeader i {
  color: #FFF;
  -webkit-text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
  -moz-text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.6); }
