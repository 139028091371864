#orderTable_filter label {
  float: right; }

.modal-dialog .modal-header {
  border-bottom: none; }
  .modal-dialog .modal-header h4 {
    display: block; }

.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 30px;
  top: 12px;
  left: 20px; }

.switch input {
  display: none; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s; }

input:checked + .slider {
  background-color: #2196F3; }

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3; }

input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px); }

.red {
  color: #c65e62; }

.green {
  color: #609d44; }

.hide {
  display: none; }

.onOff {
  position: absolute;
  left: 90px;
  top: -7px; }
