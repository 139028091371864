.card-container {
  display: table-cell;
  width: 1%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  font-size: .45em; }

.card {
  display: inline-block;
  background: #fff;
  border-radius: 0.25em;
  box-shadow: 0 0.039em 0.132em rgba(0, 0, 0, 0.4);
  font-size: 1em;
  height: 3em;
  overflow: hidden;
  position: relative;
  margin-left: .5em;
  margin-right: .5em;
  vertical-align: middle;
  width: 5em; }
  .card:nth-of-type(5) {
    margin-right: 0; }

.visa {
  font-family: "Open Sans", sans-serif;
  font-size: 2em;
  font-style: italic;
  font-weight: 800;
  padding: .05em;
  z-index: 0; }
  .visa:before, .visa:after {
    background: #00579F;
    border-top-left-radius: 0.25em;
    border-top-right-radius: 0.25em;
    content: "";
    display: block;
    height: .75em;
    left: 50%;
    position: absolute;
    top: 1.75%;
    transform: translate(-50%, 0);
    width: 96%;
    z-index: 200; }
  .visa:after {
    background: #FBA31B;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
    bottom: 1.75%;
    top: auto; }
  .visa p {
    color: #00579F;
    line-height: 3em;
    position: relative; }
    .visa p:after {
      border-top: .1em solid transparent;
      border-bottom: .1em solid transparent;
      border-left: 0.4em solid #FBA31B;
      border-radius: 0.0625em;
      content: "";
      display: block;
      height: 0;
      left: 25.5%;
      position: absolute;
      top: 37.25%;
      transform: rotateZ(189deg);
      width: 0;
      z-index: 600; }

.mc {
  background: #0059B3;
  font-size: 2em; }
  .mc:before, .mc:after {
    background: #EF3526;
    border-radius: 50%;
    content: "";
    display: block;
    height: 2em;
    left: 35%;
    position: absolute;
    top: 50%;
    width: 2em;
    transform: translate(-50%, -50%);
    z-index: 200; }
  .mc:after {
    background: #FCB836;
    left: 65%; }
  .mc hr {
    background: #EF3526;
    border: none;
    border-top-right-radius: 0.25em;
    height: .14em;
    margin: .14em auto;
    position: relative;
    top: 25%;
    width: .65em;
    z-index: 600; }
    .mc hr:nth-of-type(1), .mc hr:nth-of-type(5) {
      transform: translate(-28%, 0); }
    .mc hr:nth-of-type(2), .mc hr:nth-of-type(4) {
      transform: translate(-10%, 0); }
    .mc hr:nth-of-type(4), .mc hr:nth-of-type(5) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0.25em; }
    .mc hr:nth-of-type(3) {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 0.25em; }
  .mc p {
    color: #fff;
    font-size: .8em;
    font-weight: 600;
    line-height: 1.5em;
    position: relative;
    text-shadow: 0.0625em 0.0625em 0.0625em black;
    top: -.8em;
    z-index: 600; }

.amex {
  background: #0971bc;
  background-image: radial-gradient(top left, circle farthest-corner, #7ecdf2, #0971bc 60%);
  font-size: 2em;
  font-weight: 900; }
  .amex hr {
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
    margin: .15em 0; }
  .amex .svg {
    color: #FFF;
    font-size: .85em;
    left: 0;
    position: absolute;
    top: 0;
    stroke-width: 1; }

.discover {
  font-size: 2em; }
  .discover:after {
    border-top: .75em solid transparent;
    border-bottom: 0 solid transparent;
    border-right: 1.75em solid #FF7800;
    bottom: 0;
    content: "";
    height: 0;
    position: absolute;
    right: 0;
    width: 0; }
  .discover p {
    font-size: .75em;
    font-weight: bold;
    letter-spacing: .0625em;
    line-height: 4em;
    position: relative;
    text-transform: uppercase; }
    .discover p:after {
      background: #FF7800;
      border-radius: 50%;
      content: "";
      display: block;
      height: .85em;
      left: 3.11em;
      position: absolute;
      top: 1.5em;
      width: .85em; }
      @media (min-width: 25.625em) {
        .discover p:after {
          top: 1.59em; } }

.back {
  background: #ccc;
  font-size: 2em;
  padding: .5em .12em; }
  .back:before {
    background: #333;
    content: "";
    display: block;
    height: .6em;
    width: 100%; }
  .back:after {
    background: #333;
    border: 0.2em solid #fff;
    content: "";
    display: block;
    height: .55em;
    margin-left: 0.2em;
    margin-top: 0.28em;
    width: 55%; }
  .back span {
    background: #333;
    border: 0.15em solid #fff;
    display: block;
    height: .43em;
    margin-left: .2em;
    margin-top: .5em;
    position: absolute;
    right: .65em;
    top: 0.95em;
    width: .55em;
    z-index: 0; }
    .back span:before {
      border: 0.15em solid red;
      border-radius: 50%;
      content: "";
      height: .95em;
      left: -0.35em;
      position: absolute;
      top: -0.4em;
      width: .95em;
      z-index: 600; }
