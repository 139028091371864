form .well h3 {
  margin-top: 0px; }

form .well li.no-style {
  list-style-type: none; }

form .well .product-interest .row .checkbox {
  margin-bottom: 1px;
  margin-top: 1px; }

.registration-details h4 {
  margin: 0; }

.registration-details address {
  margin: 0; }

.registration-details .faded-divider {
  margin: 15px 0; }
