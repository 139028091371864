.modal-header {
  padding: 15px;
  border-bottom: none; }

.modal-open .modal-body h4 {
  margin: 0 0 10px 0;
  width: 95%; }

.modal-open .modal-body table {
  font-size: .8em;
  margin-bottom: 5px; }

.modal-open .modal-body .alert {
  margin-top: 0;
  margin-bottom: 10px;
  padding: 8px 10px; }

.modal-open .modal-body .total {
  padding-top: 5px; }

.modal-footer {
  border-top: none; }
  .modal-footer .btn {
    border-radius: 0; }
